import { Box, Icon } from "@chakra-ui/react";

export function MissingState() {
  return (
    <Box bg={"subtle"} p={10} rounded={"full"}>
      <Icon
        viewBox="0 0 24 24"
        strokeWidth="1.25"
        stroke="muted"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        boxSize={24}
        opacity={0.6}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
        <path d="M5 7v-2a2 2 0 0 1 2 -2h7l5 5v2"></path>
        <path d="M19 19a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2"></path>
        <path d="M5 16h.01"></path>
        <path d="M5 13h.01"></path>
        <path d="M5 10h.01"></path>
        <path d="M19 13h.01"></path>
        <path d="M19 16h.01"></path>
      </Icon>
    </Box>
  );
}
