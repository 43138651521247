import {
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "@remix-run/react";
import { MissingState } from "~/components/empty-states/missing.tsx";
import { T } from "~/libs/i18n";

export default function FourOhFour() {
  return (
    <>
      <Flex
        width={"full"}
        h={{ base: "sm", md: "lg" }}
        background={"lightBlue"}
        flexDirection={"column"}
        justify={"center"}
        align={"center"}
        p={8}
      >
        <MissingState />
        <Text
          textStyle={"heading"}
          size={{ base: "md", md: "md" }}
          textAlign={"center"}
          color={"brand.200"}
        >
          <T>Page not found</T>
        </Text>
      </Flex>
      <Container py={{ base: "16", md: "24" }}>
        <SimpleGrid columns={{ base: 1 }} columnGap={16} rowGap={4}>
          <Heading size={{ base: "md", md: "lg" }}>
            <T>We couldn't find the page you're looking for.</T>
          </Heading>
          <Stack spacing={{ base: "6", md: "8" }} justifyContent="center">
            <Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
              <T>The page may have been moved or removed.</T>
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing="3">
              <Button as={RouterLink} to={"/"} variant="primary">
                <T>Start from dashboard</T>
              </Button>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </>
  );
}
